import React from "react";
import PropTypes from "prop-types";
import HeroSlider, { OverlayContainer, Overlay, Slide, MenuNav, SideNav, ButtonsNav, Nav } from "hero-slider";
import HeroWrapper from "../HeroWrapper/herowrapper";
import HeroTitle from "../HeroTitle/herotitle";
import HeroSubtitle from "../HeroSubtitle/herosubtitle";
import HeroSlide from "../HeroSlide/heroslide";
import { useStaticQuery, graphql } from 'gatsby';
import MDRender from "../mdrender"
import * as  classes from "./herobanner.module.css";
import _ from 'lodash';

import { withPrefix } from "gatsby"

const HeroBanner = () => {

  const data = useStaticQuery(graphql`
    query {

      global: strapiGlobal {
        id
        company
        address
        duns
        email
        tel
        twitter
        web
        tagLine
        banner_images {
          title
          subtitle
          order
          image {
            localFile {
                publicURL
            }
            id
          }
        }
        body {
          data {
            body
          }
        }
      }
      siteMetadata: site(siteMetadata: {}) {
        siteMetadata {
          description
          logo
          siteUrl
          title
          posturl
          captchaurl
          tagLine
          cookieDebug
        }
      }

    }
    `)

    let siteData = _.merge( _.pick(data.siteMetadata.siteMetadata,['description','title','logo','siteUrl','posturl','captchaurl','tagLine','cookieDebug']),
                            _.pick(data.global,['id','company','address','duns','email','tel','twitter','web','tagLine']),
                            _.pick(data.global.body.data,['body'] ),
                    )
    let bannerData =  _.orderBy( data.global.banner_images.map( item =>
                            _.merge( _.pick( item, ["title","subtitle","order"] ),
                            {image: item.image.localFile.publicURL } )
                        ), ['order'],['asc'] );

  const backupImage = bannerData[0].image;
  //console.debug( bannerData )
//autoplay
  return (
    <HeroSlider
      style={{
              backgroundColor: "#000"}}
      autoplay
      slidingAnimation="top_to_bottom"
      orientation="vertical"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={(nextSlide) => console.log("onChange", nextSlide)}
      onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
      settings={{
        slidingDuration: 400,
        slidingDelay: 10,
        shouldAutoplay: true,
        shouldDisplayButtons: true,
        autoplayDuration: 8000,
        height: "100vh"
      }}
    >


      { bannerData.map((item, index) => {
        //console.debug( item, index );
        return(
          <HeroSlide key={ item.title } label={ item.title } description={ item.subtitle } backgroundImage={ item.image  } />
        )
      })}

     <Nav   />
    </HeroSlider>
  );
}

export default HeroBanner;
