import React from "react";
import PropTypes from "prop-types";
import { Overlay,  Slide } from "hero-slider";
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
//import * as  classes from "./heroslide.module.css";
import HeroWrapper from "../HeroWrapper/herowrapper";
import HeroTitle from "../HeroTitle/herotitle";
import HeroSubtitle from "../HeroSubtitle/herosubtitle";

const HeroSlide = ({ label, description, backgroundImage }) => {
  //console.debug( "B", backgroundImage );
  return (
    <Slide
      key={label}
      shouldRenderMask
      label={ label }
      navDescription={ description }
      background={{
        backgroundImageSrc:  backgroundImage,
        backgroundBlendMode: "luminosity",
        maskBackgroundBlendMode: "luminosity",
      }}
    >

    <Overlay>
       <HeroWrapper>
           <HeroTitle>{label}</HeroTitle>
           <HeroSubtitle>{description}</HeroSubtitle>
       </HeroWrapper>
    </Overlay>

    </Slide>
  )
}

HeroSlide.propTypes = {
    backgroundImage: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string
}

HeroSlide.defaultProps = {
  backgroundImage: '',
  label: '',
  description: ''
}

export default HeroSlide
